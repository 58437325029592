
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');


::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .list-container::-webkit-scrollbar-thumb {
    background: #821619;
    border-radius: 10px;
  }
 
  .list-container::-webkit-scrollbar-thumb:hover {
    background: #620306;
    border-radius: 10px;
  }

  .group-details::-webkit-scrollbar-thumb {
    background: #821619;
   
  }
 
  .group-details::-webkit-scrollbar-thumb:hover {
    background: #620306;
    
  }

.page-trupe{
    min-height: 92.5vh;
    height: fit-content;
    width: 100vw;
    background: linear-gradient(180deg, black, #4e1010);
    background-position: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}

.main-container{
    height: 60vh;
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  /*  flex-wrap: wrap; */
}

.group-list{
    width: 25vw;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.list-container{
    overflow: scroll;
    height: 40vh;
    width: 85%;
}

.group-details, .list-container{
    overflow-x: hidden;
}

.group-item{
    color: #f6f6f6;
    min-height: 100px;
    margin: 16px 0px;
    background-color: #821619;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}


.group-item span {
    display: block;
    margin: 4px;
}

.group-details{
    background-color: #EFE5D0;
    height: 100%;
    width: 55vw;
    min-width: fit-content;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 400px;
    flex-grow: 1;

   
}

.group-details h1 {
    color: black;
}

@media (max-width: 750px){
    .main-container{
        height: auto;
         width: 80vw;
         display: flex;
         flex-direction: column;
         justify-content: flex-start;
         align-items: center;
         gap: 5vh;
         margin-bottom: 5vh;
       /*  flex-wrap: wrap; */
     }

     .group-list{
        width: 80vw;
     }

     .list-container{
        width: 85%;
     }

     

     .trupe-header{
        width: 90vw !important;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
    
    }
}



.trupe-header{
    width: 50vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 24px;
}

h1{
    color: #f6f6f6;
}

.category-choice{
    background-color: #4e1114;
    height: 50px;
    width: 100px;
}

.container-trupa{
    background-color: #f6f6f6;
    width: 80%;
    height: fit-content;
    padding: 16px 32px 16px 16px;
    margin: 12px 24px;
    display: flex;
    position: relative;
    flex-direction: column;
border-radius: 5px;
}

.container-trupa .membri {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}
.container-trupa .container-contact{
    margin-top: 16px;
    display: flex;
    flex-direction: column;
}

.container-piesa{
    background-color: #f6f6f6;
    width: 80%;
    height: fit-content;
    padding: 16px 32px 16px 16px;
    margin: 12px 24px;
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 5px;
}

.container-piesa .durata{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #4e1114;
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.container-decor{
    width: 80%;
    height: fit-content;
    padding: 16px 32px 16px 16px;
    margin: 12px 24px;
    display: flex;
    position: relative;
    flex-direction: column;
}

.container-decor .decor{
    width: 60%;
}

.container-decor .demontare{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #4e1114;
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}
.container-decor .montare{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: darkgreen;
    position: absolute;
    right: 66px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.green {
    background-color: darkgreen;
}

.red {
    background-color: #4e1114;
}

.container-butoane{
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0;
}


.buton{
    color: #f6f6f6;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    cursor: pointer;
    background-color: #821619;
    margin: 0 8px;

}


.trupe-btn {
    padding: 10px;
    margin: 0px 10px;
    border-radius: 10px;
    background-color: black;
    color: white;
    border: none;
}

#search {
    width: 85%;
    border-radius: 10px;
    border: 30px soild white !important;
   /* background-color: #adadad; */
    margin-bottom: 2vh;
}

#search:focus {
    outline: none;
    background-color: white;
}

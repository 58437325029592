.navbar {
  height: 7.5vh;
  background-color: black;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 2rem;
}

.navbar .logo {
  --logo-size: 100px;
  width: var(--logo-size);
  height: var(--logo-size);
  object-fit: contain;
}

.navbar .options {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 1rem;
}

.navbar .options a {
  color: white;
  font-size: 1.25rem;
  text-decoration: none;
}
.navbar .options a:hover {
  text-decoration: underline;
}

.btnLogout:hover {
  cursor: pointer;
  font-weight: 900;
}

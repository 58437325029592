.body {
    min-height: 92.5vh;
    height: fit-content;
    width: 100vw;
    background: linear-gradient(180deg, black, #821619);
    background-position: cover;
  
    display: grid;
    column-gap: 100px;
    align-items: center;
    justify-content: center;
  }

  .card-container {
    background-color: white;
    width: 150px;
    height: 200px;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom:25vh
  }
  .card-container h1 {
    color: black;
    }
  .controllers{
    display: flex;
    column-gap: 15vw;
    margin-bottom: 0vh;
  }

  .titluDashboard{
    justify-content: center;
    display: flex;
    color: white;
  }

  @media only screen and (max-width: 890px) {
      .controllers {
        width: 100%;
        display: block;
      }
      .card-container {
        margin: 51px auto;
        margin-bottom: 9vh;
      }
      .body h1{
        margin: 51px auto;
      }
  }
  

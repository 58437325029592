.btn-confirm {

}

.h1-confirm {
    margin-bottom: 30px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .h1-confirm {
        font-size: 20px;
    }
}


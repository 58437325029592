.update-trupa {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 92.5vh;
    overflow-y: scroll;
}

.label-update {
    color: aliceblue;
    padding-top: 20px;
}

#asteptariFestival {
    margin-bottom: 30px;
}
/* Import Components */
@import "./components/navbar.css";
@import "./components/confirmDelete.css";
@import "./components/descriereModal.css";

/* Import Pages */
@import "./pages/dashboard.css";
@import "./pages/login.css";
@import "./pages/updateTrupa.css";

* {
  padding: 0;
  margin: 0;
}

.btn-primary {
  padding: 1rem 2rem;
  color: white;
  background-color: #4e1010;
  text-align: center;
  font-size: 1.5rem;

  cursor: pointer;
}

.btn-primary:hover {
  opacity: 0.75;
}

td {
  border: 1px solid black;
}

.login {
  width: 100vw;
  height: 100vh;
  /* background: linear-gradient(180deg, black, #4e1010); */
  background-image: url("../../images/background-login-desktop.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  column-gap: 100px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.login h1{
  justify-content: center;
  display: flex;
  font-family: "Kraftstoff";
  font-size: 3em;
}

.form {
  background-color: #f6f6f6;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 2rem;

  width: 30%;
}

.text-filds {
  display: flex;
  flex-direction: column;

  color: black;
}
label {
  font-size: 1.25rem;
  padding-bottom: 0.5rem;
}
input {
  padding: 0.5rem;
  font-size: 1rem;
}

.googleBtn {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: row;
  padding: 10px;
  border-radius: 15px;
  background-color: white;
  border: none;
  margin-top: 50px;
}

.googleBtn:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.googleIcon {
  width: 50px;
  height: 50px;
}

.googleText {
  font-size: 32px;
  position: relative;
  top: 7px;
  margin-left: 10px;
}

@media only screen and (max-width: 800px) {
  .login {
    flex-direction: column;
    gap: 1rem;
  }

  .googleBtn {
    transform: scale(0.65)
  }
}